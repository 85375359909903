import Porta101 from '_/images/porta101.svg';
import CanaltechPod from '_/images/canaltech-podcast-branco.svg';
import PodcastApple from '../../../assets/svg/podcastapple.svg';
import Deezer from '../../../assets/svg/deezer.svg';
import Spotify from '../../../assets/svg/spotify.svg';
import Amazon from '../../../assets/svg/amazon.svg';
import WrapperContentPodcast from '../WrapperContentPodcast';
import Breadcrumb, { BreadcrumbItem } from '../Breadcrumb';

export type PodcastData = {
  titulo: string;
  url: string;
  descricao: string;
  links: {
    apple: string;
    spotify: string;
    deezer: string;
    amazon: string;
  };
};

interface IPodcastsItemProps {
  podcastData: {
    porta101?: PodcastData;
    canaltech?: PodcastData;
  };
  breadcrumbs?: BreadcrumbItem[];
}

export default function PodcastItem({ podcastData, breadcrumbs }: IPodcastsItemProps) {
  const platforms = [
    {
      id: 1,
      name: 'Apple',
      icon: <PodcastApple fill="#FFBC2F" />,
      title: 'Apple Podcasts',
    },
    {
      id: 2,
      name: 'Deezer',
      icon: <Deezer />,
      title: 'Deezer',
    },
    {
      id: 4,
      name: 'Spotify',
      icon: <Spotify />,
      title: 'Spotify',
    },
    {
      id: 5,
      name: 'Amazon',
      icon: <Amazon />,
      title: 'Amazon Music',
    },
  ];

  const hasBreadcrumb = Object.keys(podcastData).length === 1 && breadcrumbs.length > 0;

  return (
    <>
      {podcastData.porta101 && (
        <section className="bg-mglGray50">
          <WrapperContentPodcast podcast="porta101" alt="Waves">
            {hasBreadcrumb && (
              <div className="ml-0 tablet:ml-8 [&>div>div>a]:text-mglGray50 [&>div>div]:text-mglGray50 [&>div]:mt-0">
                <Breadcrumb breadcrumbList={breadcrumbs} />
              </div>
            )}
            <div className="flex flex-col text-mglGray50 desktop:flex-row [@media_(max-width:992px)]:first:mx-4 [@media_(max-width:992px)]:first:my-8 [@media_(max-width:992px)]:last:ml-0">
              <div className="m-2 flex min-w-[220px] flex-1 items-center justify-center desktop:max-w-[220px]">
                <a href={podcastData.porta101.url} title="Playlist Podcast Porta 101">
                  <Porta101 width={200} height={200} />
                </a>
              </div>
              <div className="ml-6 flex-1">
                <div className="mb-6 flex flex-wrap items-center">
                  <span className="border-0 border-r border-solid border-r-mglGray50 py-2 pl-0 pr-4 text-xs">PODCAST</span>
                  <span className="ml-4 text-3xl font-medium">Seg</span>
                </div>
                <div className="mb-6 flex text-lg font-light">{podcastData.porta101.descricao}</div>
                <span className="text-sm font-medium">Ouça já nas plataformas</span>
                <div className="mt-4 flex w-full flex-wrap justify-start text-mglGray50 largeDesktop:flex-nowrap largeDesktop:justify-between [&_a]:mb-2 [&_a]:ml-0 [&_a]:mr-2 [&_a]:mt-0 [&_a]:w-full [&_a]:min-w-[120px] [&_a]:max-w-[200px]">
                  {Object.entries(podcastData.porta101.links).map((link, index) => (
                    <a
                      href={link[1]}
                      id={`pod_port_${platforms[index].name.toLowerCase()}`}
                      target="_blank"
                      title={`Ouvir em ${platforms[index].title}`}
                      rel="noreferrer"
                      key={`${platforms[index].id}-porta`}>
                      <button className="flex w-full flex-1 cursor-pointer items-center justify-center rounded-[55px] border-0 bg-mglGray900 px-4 py-2 text-xs text-mglGray50 outline-none [&_svg]:mr-2">
                        {platforms[index].icon}
                        {platforms[index].name}
                      </button>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </WrapperContentPodcast>
        </section>
      )}

      {podcastData.canaltech && (
        <section>
          <WrapperContentPodcast podcast="canaltech" alt="Waves">
            {hasBreadcrumb && (
              <div className="ml-0 tablet:ml-8 [&>div>div>a]:text-mglGray50 [&>div>div]:text-mglGray50 [&>div]:mt-0">
                <Breadcrumb breadcrumbList={breadcrumbs} />
              </div>
            )}
            <div className="flex flex-col text-mglGray50 tablet:flex-row [@media_(max-width:992px)]:first:mx-4 [@media_(max-width:992px)]:first:my-8 [@media_(max-width:992px)]:last:ml-0">
              <div className="m-2 flex min-w-[220px] flex-1 items-center justify-center desktop:max-w-[220px]">
                <a href={podcastData.canaltech.url} title="Playlist Podcast Canaltech">
                  <CanaltechPod width={200} height={200} />
                </a>
              </div>
              <div className="ml-6 flex-1">
                <div className="mb-6 flex flex-wrap items-center">
                  <span className="border-0 border-r border-solid border-r-mglGray50 py-2 pl-0 pr-4 text-xs">PODCAST</span>
                  <span className="ml-4 text-3xl font-medium">Ter a Sáb</span>
                </div>
                <div className="mb-6 flex text-lg font-light">{podcastData.canaltech.descricao}</div>
                <span className="text-sm font-medium">Ouça já nas plataformas</span>
                <div className="mt-4 flex w-full flex-wrap justify-start text-mglGray50 largeDesktop:flex-nowrap largeDesktop:justify-between [&_a]:mb-2 [&_a]:ml-0 [&_a]:mr-2 [&_a]:mt-0 [&_a]:w-full [&_a]:min-w-[120px] [&_a]:max-w-[200px]">
                  {Object.entries(podcastData.canaltech.links).map((link, index) => (
                    <a
                      href={link[1]}
                      target="_blank"
                      id={`pod_canaltech_${platforms[index].name.toLowerCase()}`}
                      title={`Ouvir em ${platforms[index].title}`}
                      rel="noreferrer"
                      key={`${platforms[index].id}-canaltech`}>
                      <button className="flex w-full flex-1 cursor-pointer items-center justify-center rounded-[55px] border-0 bg-mglGray900 px-4 py-2 text-xs text-mglGray50 outline-none [&_svg]:mr-2">
                        {platforms[index].icon}
                        {platforms[index].name}
                      </button>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </WrapperContentPodcast>
        </section>
      )}
    </>
  );
}
