import Container from '../Container';
import classNames from '@/utils/className';

interface WrapperProps {
  alt: string;
  children: JSX.Element[] | JSX.Element;
  podcast: 'porta101' | 'canaltech';
}

export default function WrapperContentPodcast({ children, alt, podcast }: WrapperProps) {
  const backgrounds = {
    porta101: {
      background_image: 'https://img.canaltech.com.br/podcast_porta101_background.svg',
      background_linear: 'after:[background:linear-gradient(180deg,_#59027C_0%,_rgba(89,_2,_124,_0.7)_0.01%,_#59027C_100%)]',
    },
    canaltech: {
      background_image: 'https://img.canaltech.com.br/podcast_canaltech_background.svg',
      background_linear: 'after:[background:linear-gradient(180deg,_rgba(12,_46,_94,_0.7)_0%,_#0C2E5E_100%)]',
    },
  } as const;

  return (
    <div style={{ position: 'relative' }}>
      <img className="absolute bottom-0 z-[2] max-h-full w-full object-cover" src={backgrounds[podcast].background_image} alt={alt} />
      <section
        className={classNames(
          'relative flex h-full w-full justify-center overflow-hidden before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0 before:bg-mglGray50 before:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:z-[1] after:content-[""]',
          backgrounds[podcast].background_linear
        )}>
        <Container className="z-[2] px-0 py-10">{children}</Container>
      </section>
    </div>
  );
}
